
import { mapState, mapGetters } from 'vuex'
import { NEW_PASSWORD_REQUEST } from '@/store/actions/user.ts'

import AlertModal from '@/components/AlertModal.vue'

export default {
    name: 'ProfileComponent',
    components: {
        AlertModal
    },
    data() {
        return {
            errors: [],
            success: null,
            valid: null,

            form: {},
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch(NEW_PASSWORD_REQUEST, this.form)
                .then(() => {
                    this.success = 'Wachtwoord gewijzigd'
                })
                .catch((err) => {
                    this.errors.push(err.response.data.error)
                })
        },
        validate() {
            var required_fields = ['password', 'new_password', 'verify_password']

            let verify_warning = this.$refs['verify_password-warning']
            let password_warning = this.$refs['password-warning']
            verify_warning.innerHTML = null
            password_warning.innerHTML = null

            for (let i=0; i<required_fields.length; i++) {
                if (!(this.form[required_fields[i]])) return false
            }

            if (this.form.new_password.length < 7) {
                password_warning.innerHTML =
                    'Nieuw wachtwoord moet minimaal 7 characters lang zijn'
                return false
            } else if (this.form.new_password !== this.form.verify_password) {
                verify_warning.innerHTML =
                    'Komt niet overeen met het nieuwe wachtwoord'
                return false
            }

            return true
        },
    },
    computed: {
        ...mapState({
            profile: (state: any) => state.user.profile
        }),
        ...mapGetters(['isStudent']),
    },
    watch: {
        form: {
            deep: true,
            handler() {
                this.valid = this.validate()
            }
        }
    },
}
