
// progressbar.js@1.0.0 version is used
// Docs: http://progressbarjs.readthedocs.org/en/1.0.0/
var ProgressBar = require('progressbar.js')

export default {
    data: function() {
        return {
            id: Math.random().toString(36).substring(2, 15), // + Math.random().toString(36).substring(2, 15)
            bar: null
        }
    },
    props: {
        value: {type: Number, default: 0},
        min_value: {type: Number, default: null},
        max_value: {type: Number, default: 100},
        subtext: {type: String, default: null},
        size: {type: String, default: '150px'},
        from_color: {type: String, default: '#ff0000'},
        to_color: {type: String, default: '#00a100'},
        font_size: {type: String, default: '2rem'},
        font_color: {type: String, default: '#aaa'},
        trail_color: {type: String, default: '#aaa'},
    },
    methods: {
        renderProgress: function() {
            let vm = this

            var from_color = vm.from_color
            var to_color = vm.to_color

            if (vm.min_value !== null) {
                if (vm.value >= vm.min_value) {
                    from_color = vm.to_color
                } else {
                    to_color = vm.from_color
                }
            }

            this.bar = new ProgressBar.Circle('#progress-circle-' + this.id, {
                color: vm.font_color,
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: 8,
                trailWidth: 2,
                trailColor: vm.trail_color,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: false
                },
                from: { color: from_color, width: 4 },
                to: { color: to_color, width: 8 },
                // Set default step function for all animate calls
                step: function(state, circle) {
                    circle.path.setAttribute('stroke', state.color)
                    circle.path.setAttribute('stroke-width', state.width)

                    var value
                    if (vm.subtext === null) {
                        value = Math.round(circle.value() * 100)
                        if (value === 0) {
                            circle.setText('')
                        } else {
                            circle.setText(value + '%')
                        }
                    } else {
                        value = Math.round(circle.value() * vm.max_value)
                        circle.setText(value + '<br />' + vm.subtext)
                    }
                },
            })
            this.bar.text.style.fontSize = vm.font_size

            this.animate()
        },
        animate() {
            if (!(typeof(this.value) === 'number')) return
            if (this.value && this.max_value) {
                if (this.value > this.max_value) {
                    this.bar.animate(1)
                } else {
                    this.bar.animate(this.value / this.max_value)  // Number from 0.0 to 1.0
                }
            }
        },
        reInitializeProgress() {
            this.bar.destroy()
            this.renderProgress()
        }
    },
    mounted() {
        if (!(typeof(this.value) === 'number')) return
        this.renderProgress()
    },
    computed: {
        cssCustomCircle: function() {
            return {
                '--size': this.size
            }
        }
    },
    watch: {
        value: function(newValue, oldValue) {
            if (
                (newValue < this.min_value && oldValue >= this.min_value) ||
                (newValue >= this.min_value && oldValue < this.min_value)
            ) return this.reInitializeProgress()

            if (newValue < oldValue) this.bar.set(0)
            this.animate()
        },
        max_value: function() {
            this.reInitializeProgress()
        },
    },
}
