
// @ is an alias to /src
import ExcursionCard from '@/components/excursions/ExcursionCard.vue'
import ActivityInfo from '@/components/excursions/ActivityInfo.vue'

import { EXCURSION_REQUEST } from '@/store/actions/excursion.ts'
import { SUBSCRIPTIONS_REQUEST } from '@/store/actions/subscription.ts'

import { mapGetters } from 'vuex'

//import Banner from '@/components/Banner.vue'

export default {
    name: 'MijnExcursies',
    data: function() {
        return {
            excursion_info: {},
        }
    },
    components: {
        ExcursionCard,
        ActivityInfo
    },
    methods: {
        showActivityInfo: function(excursion) {
            this.excursion_info = excursion
            this.$bvModal.show('acivity-info-modal')
        },
        showExcursionAdminModal: function(excursion) {
            this.excursion_info = excursion
            this.$bvModal.show('excursion-admin-modal')
        },
        loadExcursions: function() {
            this.$store.dispatch(EXCURSION_REQUEST).then()
        },
        loadSubscriptions() {
            this.$store.dispatch(SUBSCRIPTIONS_REQUEST).then()
        },
    },
    created: function() {
        this.loadExcursions()
    },
    computed: {
        ...mapGetters(['getMyExcursions', 'isAdmin', 'isTeacher'])
    },
}
