<template>
  <div>
    <h1>Account</h1>
    <p v-if="profile.name">
      <strong>{{ profile.name }}</strong>
    </p>
    <div class="tab-wrapper">
        <b-tabs justified>
            <b-tab title="Mijn gegevens" active>
                <div class='tab-content'>
                    <Profile />
                    <hr />
                    <Password />
                    <hr />
                    <ID />
                </div>
            </b-tab>
            <b-tab title="Mijn activiteiten">
                <div class='tab-content'>
                    <MyExcursions />
                </div>
            </b-tab>
            <b-tab title="X-Week overzicht">
                <div class='tab-content'>
                    <h4>{{ profile.first_name }}</h4>
                    <template v-if='profile.hours'>
                        Je moet je inschrijven voor minimaal <b>{{ profile.hours }} punten</b> aan activiteiten
                        <br />
                        <br />
                        <!--<Progress :percentage=percentage :value=value subject="punten" />-->
                        <ProgressCircle
                            :value='getMyHours'
                            :max_value='(getMyHours <= profile.hours) ? profile.hours : getMyHours'
                            subtext="punten"
                            size='250px'
                            font_size='3rem'
                        />
                        <br />
                    </template>
                    <template v-else-if='isAdmin || isTeacher'>
                        Studenten zien hier het overzicht van aantal punten dat ze moeten besteden aan activiteiten.
                    </template>
                    <template v-else>
                        De hoeveelheid punten je minimaal moet maken kan niet worden bepaald...
                    </template>
                </div>
            </b-tab>
        </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ProgressCircle from '@/components/ProgressCircle'

import MyExcursions from '@/components/account/MyExcursions.vue'
import Profile from '@/components/account/Profile.vue'
import Password from '@/components/account/Password.vue'
import ID from '@/components/account/ID.vue'

export default {
    name: 'account',
    data: function() {
        return {
            mounted: false,
        }
    },
    components: {
        ProgressCircle,
        MyExcursions,
        Profile,
        Password,
        ID,
    },
    mounted() {
        this.mounted = true
    },
    computed: {
        ...mapState({
            profile: state => state.user.profile
        }),
        ...mapGetters(['isAdmin', 'isTeacher', 'getMyHours']),
    }
}
</script>

<style lang="scss">
div.tab-wrapper ul.nav-tabs {
    padding-top: 10px;
    /*
    background-color: #f4f4f1;
    */
}

div.tab-wrapper .nav-item {
    background-color: #17a2b8;
    a {
        color: rgba(255, 255, 255, 0.75);
    }
}
div.tab-wrapper .nav-item.active {
    font-size: larger;
}

div.tab-content {
    padding: 10px;
}

li.nav-item a {
    height: 100%;
}
</style>
